import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBookingRegistry, LazyBookingRegistryEdit, LazyBookingSummary, LazyTooManyBookingRequestDialog, LazyBookingCard, LazyBookingList, LazyBookingSteps, LazyBookingStepsRealty, LazyBookingFinishing, LazyBookingOffer, LazyBookingPayment, LazyBookingPersonal, LazyBookingPurchaseConditions, LazyHeader, LazyBookingSummaryCard, LazyBookingSummaryInfo, LazyRealtyList, LazyAcceptanceDialog, LazyAcceptanceDialogOwners, LazyAcceptances, LazyHouseFilter, LazyHouseGrid, LazyImportRealtyExcelButton, LazyRealtyFilter, LazyRealtyGrid, LazyCalendarAcceptance, LazyMonitoring, LazyMonitoringDay, LazyMonitoringFilters, LazyMonitoringPlanning, LazyMonitoringWeek, LazyObjectCard, LazyAppointment, LazyAcceptanceCompleted, LazyAcceptanceScheduled, LazyAcceptanceScheduledCancel, LazyAcceptanceScheduledSuccess, LazyDate, LazyDateSuccessDialog, LazyPersonalData, LazyPersonalDataOwner } from '#components'
const lazyGlobalComponents = [
  ["BookingRegistry", LazyBookingRegistry],
["BookingRegistryEdit", LazyBookingRegistryEdit],
["BookingSummary", LazyBookingSummary],
["TooManyBookingRequestDialog", LazyTooManyBookingRequestDialog],
["BookingCard", LazyBookingCard],
["BookingList", LazyBookingList],
["BookingSteps", LazyBookingSteps],
["BookingStepsRealty", LazyBookingStepsRealty],
["BookingFinishing", LazyBookingFinishing],
["BookingOffer", LazyBookingOffer],
["BookingPayment", LazyBookingPayment],
["BookingPersonal", LazyBookingPersonal],
["BookingPurchaseConditions", LazyBookingPurchaseConditions],
["Header", LazyHeader],
["BookingSummaryCard", LazyBookingSummaryCard],
["BookingSummaryInfo", LazyBookingSummaryInfo],
["RealtyList", LazyRealtyList],
["AcceptanceDialog", LazyAcceptanceDialog],
["AcceptanceDialogOwners", LazyAcceptanceDialogOwners],
["Acceptances", LazyAcceptances],
["HouseFilter", LazyHouseFilter],
["HouseGrid", LazyHouseGrid],
["ImportRealtyExcelButton", LazyImportRealtyExcelButton],
["RealtyFilter", LazyRealtyFilter],
["RealtyGrid", LazyRealtyGrid],
["CalendarAcceptance", LazyCalendarAcceptance],
["Monitoring", LazyMonitoring],
["MonitoringDay", LazyMonitoringDay],
["MonitoringFilters", LazyMonitoringFilters],
["MonitoringPlanning", LazyMonitoringPlanning],
["MonitoringWeek", LazyMonitoringWeek],
["ObjectCard", LazyObjectCard],
["Appointment", LazyAppointment],
["AcceptanceCompleted", LazyAcceptanceCompleted],
["AcceptanceScheduled", LazyAcceptanceScheduled],
["AcceptanceScheduledCancel", LazyAcceptanceScheduledCancel],
["AcceptanceScheduledSuccess", LazyAcceptanceScheduledSuccess],
["Date", LazyDate],
["DateSuccessDialog", LazyDateSuccessDialog],
["PersonalData", LazyPersonalData],
["PersonalDataOwner", LazyPersonalDataOwner],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
